import React from 'react'
import Layout from 'layouts/Page'
import SEO from 'components/SEO/SEO'

const NotFoundPage = () => (
  <div id="error-page">
    <div className="content-wrap">
      <section
        className="form-title page-title"
        style={{ textAlign: 'center' }}
      >
        <h2>404 Page Not Found</h2>
        <p className="hint-text">
          Hmmm... this page isn't here. Maybe it was deleted or maybe it never
          existed.
        </p>
      </section>
    </div>
  </div>
)

export default ({ data, location }) => (
  <Layout navbar="main" location={location}>
    <SEO />
    <NotFoundPage />
  </Layout>
)
